//=include scrollLottie.js
//=include lottieAnimation.js

let menu_button = document.getElementById("navbar-toggler"),
    navbar = document.getElementById("navigation-bar"),
    menu_main = document.getElementById('menu-panel');


const timeline = gsap.timeline({ defaults: { ease: "power1.out" } });
function toggleMenu() {

    [menu_button, menu_main].forEach((element) => element.classList.toggle("active"));

    const is_menu_active = menu_main.classList.contains('active'),
        navbar_container = navbar.querySelector("div");

    const animate_menu = () => {
        timeline.to(menu_main, { display: "block", duration: 0.3 }, "-=3").to(menu_main, { opacity: 7, duration: 0.3 }, "-=2.5").to(navbar_container, { background: "rgb(238 43 56 / 0%)", duration: 0.3 }, "-=2.5").set(navbar, { className: "navbar wrapper active"}, "-=2.5");
        
        document.body.style.overflowY = "hidden";
    }

    if (is_menu_active) animate_menu();
    if (!is_menu_active) {
        setTimeout(() => navbar_container.style.removeProperty("background"), 3000);
        document.body.style.removeProperty("overflow-y");
    };

    animate_menu_item();
};

menu_button.onclick = toggleMenu;

anim = timeline.fromTo(".menu-item", { x: "-100vw" }, { x: "0", duration: 0.8, stagger: 0.15 }).fromTo(".menu-item", { lineHeight: 0.6 }, { lineHeight: 0.9, duration: 0.8 }).reversed(true);

function animate_menu_item() {
    this.anim.reversed(!this.anim.reversed());
}