let container = document.getElementsByClassName("lottie-wrapper");
let animate = [];
for (let i = 0; i < container.length; i++) {
    if (document.getElementById(container[i].id).getAttribute('data-scroll') !== "true") {
        const animateDate = {
            container: document.getElementById(container[i].id),
            remderer: "svg",
            loop: document.getElementById(container[i].id).getAttribute('data-loop'),
            autoplay: true,
            prerender: true,
            rendererSettings: {
                progressiveLoad: true,
            },
            path: `json/${document.getElementById(container[i].id).getAttribute('data-name')}.json`
        };
        animate[container[i].id] = bodymovin.loadAnimation(animateDate);
        animate[container[i].id].setSpeed(document.getElementById(container[i].id).getAttribute('data-speed'));
    } else {
        ScrollLottie({
            container: document.getElementById(container[i].id),
            trigger: document.getElementById(container[i].id).getAttribute('data-target'),
            endTrigger: document.getElementById(container[i].id).getAttribute('data-target-end'),
            loop: document.getElementById(container[i].id).getAttribute('data-loop'),
            duration: document.getElementById(container[i].id).getAttribute('data-speed'),
            // speed: "medium",
            path: `json/${document.getElementById(container[i].id).getAttribute('data-name')}.json`,
        });
    }
}