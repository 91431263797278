const ScrollLottie = (obj) => {
    let anim = lottie.loadAnimation({
        container: obj.container,
        renderer: "svg",
        loop: obj.loop,
        autoplay: true,
        path: obj.path,
    });

    let timeObj = { currentFrame: 0 };
    // let endString =
    //   obj.speed === "slow"
    //     ? "+=2000"
    //     : obj.speed === "medium"
    //     ? "+=1000"
    //     : obj.speed === undefined
    //     ? "+=1250"
    //     : "+=500";
    ScrollTrigger.create({
        trigger: obj.trigger,
        endTrigger: ((obj.trigger) !== "undefined") ? obj.endTrigger : obj.trigger,
        // scrub: true,
        // pin: false,
        // end: "30%+=100px center",
        // start: "top bottom",
        // markers: true,
        start: "top =+85%",
        end: "bottom =+85%",
        // start: "top =+80%",
        // end: "bottom =+80%",
        defaults: {
            ease: "power2.inOut",
        },
        toggleActions: "play none none reverse",

        onUpdate: (self) => {
            if (obj.duration) {
                gsap.to(timeObj, {
                    duration: obj.duration,
                    currentFrame: Math.floor(self.progress * (anim.totalFrames - 1)),
                    onUpdate: () => {
                        anim.goToAndStop(timeObj.currentFrame, true);
                    },
                    ease: "expo",
                });
            } else {
                anim.goToAndStop(self.progress * (anim.totalFrames - 1), true);
            }
        },
        onToggle: (scrollTrigger) => {
            // refresh because height start changes
            scrollTrigger.refresh();
        },
    });
};